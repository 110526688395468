import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { RemoteSettingsService } from '@frontend/shared/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../environments/environment';

@Injectable()
export class DefaultRouteGuard  {
  constructor(
    private router: Router,
    private remoteSettingsService: RemoteSettingsService
  ) {}

  canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const navigateToDefaultRouteIfSpecified$ = this.remoteSettingsService.clientSettings$.pipe(
      map((settings) => {
        const defaultRoute = settings.defaultRoute ?? environment.defaultRoute;
        if (defaultRoute) this.router.navigate([defaultRoute]);
        else return true;
      })
    );

    return navigateToDefaultRouteIfSpecified$;
  }
}
