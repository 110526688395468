// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appVersion: '1.02.02',
  adyenClientKey: 'test_W6AABNCWN5HQ3IC4ECS2GHP3YIGZUXUM',
  paymentSuccess: 'pay/step4',
  defaultRoute: 'en/pay', // used as fallback if no defaultRoute is specified in RemoteSettingsService
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error'; // Included with Angular CLI.
