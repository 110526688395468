import { Component, OnInit } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import {
  LanguagesService,
  EnvironmentService,
  AppReadyService,
  AppReadyState,
} from '@frontend/shared/services';
import { TranslateService } from '@ngx-translate/core';
import { tap, filter } from 'rxjs/operators';
import { Observable, combineLatest } from 'rxjs';

@Component({
  selector: 'frontend-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  menuItems: Array<{ text: string; href: string }>;
  logoSrc: string;
  languagePickerEnabled: boolean;
  updateTitleStream$: Observable<any>;

  constructor(
    private languageService: LanguagesService,
    private environmentService: EnvironmentService,
    private router: Router,
    private translateService: TranslateService,
    private appReadyService: AppReadyService
  ) {
    const { logoSrc, menuItems, title } = this.environmentService.environment;
    this.logoSrc = logoSrc;
    this.menuItems = menuItems;
    const translationReady$ = this.appReadyService.readyStates$.pipe(
      filter((appReadyStates) => {
        return appReadyStates?.includes(AppReadyState.TRANSLATION);
      })
    );
    const translatedTitle$ = this.translateService.stream(title);
    this.updateTitleStream$ = combineLatest(
      translationReady$,
      translatedTitle$
    ).pipe(
      tap((result) => {
        const [, translatedTitle] = result;
        const titleHTML = window.document.getElementsByTagName('title')[0];
        titleHTML.innerHTML = translatedTitle;
      })
    );
  }

  setLanguage() {
    this.router.events.subscribe((val) => {
      if (val instanceof RoutesRecognized) {
        const paramLang = val.state.root.firstChild.params.lang;
        const isCurrentLang =
          this.languageService.getCurrentLang() &&
          paramLang === this.languageService.getCurrentLang().id;
        if (!isCurrentLang) {
          this.languageService.setCurrentLang(paramLang);
        }
      }
    });
  }

  ngOnInit() {
    this.setLanguage();
    this.languagePickerEnabled = this.environmentService.environment.languagePickerEnabled;
  }
}
