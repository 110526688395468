import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedComponentsModule } from '@frontend/shared/components';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApplicationInsightsService } from '@frontend/shared/interfaces';
import { ApplicationInsightsBasicService } from '@frontend/shared/services';
// for Core import:
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';

import { AppComponent } from './app.component';
import {
  TranslateModule,
  TranslateLoader,
  MissingTranslationHandler,
} from '@ngx-translate/core';
import { HttpTranslateFactory } from '@frontend/shared/helpers';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import {
  EnvironmentService,
  LanguagesService,
  RemoteSettingsService,
  EnvironmentServiceFactory,
  ApplicationInsightsErrorHandler,
  initializeRemoteSettings,
  AppReadyService,
} from '@frontend/shared/services';
import { environment } from '../environments/environment';
import { MissingTranslationHelper } from '@frontend/shared/helpers';
import { DefaultRouteGuard } from './default-route.guard';
import { DefaultRouteComponent } from './default-route.component';

const routes: Routes = [
  {
    path: ':lang',
    children: [
      {
        path: 'pay',
        loadChildren: () =>
          import('@frontend/shared/payment').then((m) => m.SharedPaymentModule),
      },
      {
        path: 'test',
        loadChildren: () =>
          import('@frontend/shared/test').then((m) => m.SharedTestModule),
      },
      {
        path: '**',

        canActivate: [DefaultRouteGuard],
        component: DefaultRouteComponent,
        pathMatch: 'full',
      },
    ],
  },
  {
    path: '**',
    component: DefaultRouteComponent,
    canActivate: [DefaultRouteGuard],
    pathMatch: 'full',
  },
];

@NgModule({
  declarations: [AppComponent, DefaultRouteComponent],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes),
    SharedComponentsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    // for HttpClient use:
    LoadingBarHttpClientModule,

    // for Core use:
    LoadingBarModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpTranslateFactory,
        deps: [HttpClient, EnvironmentService, AppReadyService],
      },
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeRemoteSettings,
      deps: [RemoteSettingsService],
      multi: true,
    },
    RemoteSettingsService,
    {
      provide: EnvironmentService,
      useFactory: (remoteSettingsService: RemoteSettingsService) =>
        EnvironmentServiceFactory(environment, remoteSettingsService),
      deps: [RemoteSettingsService],
    },
    LanguagesService,
    {
      provide: ErrorHandler,
      useClass: ApplicationInsightsErrorHandler,
    },
    {
      provide: ApplicationInsightsService,
      useClass: ApplicationInsightsBasicService,
    },
    {
      provide: MissingTranslationHandler,
      useClass: MissingTranslationHelper,
    },
    DefaultRouteGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
