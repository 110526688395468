<ngx-loading-bar [includeSpinner]="false" color="#005A30"></ngx-loading-bar>

<ng-container *ngIf="updateTitleStream$ | async"></ng-container>

<div *ngIf="languagePickerEnabled" class="language-picker-wrapper">
  <frontend-language-picker-container
    class="language-picker"
  ></frontend-language-picker-container>
</div>

<frontend-header-only-layout>
  <frontend-header [menuItems]="menuItems" [logoSrc]="logoSrc">
  </frontend-header>
</frontend-header-only-layout>
